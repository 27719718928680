<template>
  <v-card class="card-shadow border-radius-xl">
    <common-loading :title="loading.title" :message="loading.message" :loading_dialog="loading.dialog"
      :show_loading="loading.show_loading" :show_confirm="loading.show_confirm"
      v-on:confirm="loading.dialog = false"></common-loading>
    <common-confirm :title="confirm.title" :message="confirm.message" :confirm_dialog="confirm.dialog"
      :confirm_function="confirm.function" v-on:confirm="confirm.dialog = false"
      v-on:cancel="confirm.dialog = false"></common-confirm>
    <v-dialog v-model="import_dialog" width="60%" height="80%" class="pa-0 ma-0">
      <v-card class="ma-0 pa-0 d-flex flex-column">
        <v-card-text>
          <v-card-title class="justify-center ml-2 mr-4 py-2">
            <v-spacer></v-spacer>
            <v-btn icon @click="closeImportDialog()" class="pa-0 ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-row>
            <v-col cols="10">
              <label class="text-sm text-typo font-weight-bolder ms-1">檔案</label>
              <v-file-input :accept="getDialogSuffix()" :label="getDialogLabel()" v-model="file"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions bottom class="mx-4 my-0 py-0">
          <v-btn elevation="0" :ripple="false" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-secondary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            " color="#5e72e4" @click="closeImportDialog()">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn elevation="0" :ripple="false" class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mt-6
              mb-2
            " @click="handleFileSelect()">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="justify-center py-2 px-4">
      <h5 class="font-weight-bold text-h5 text-grt mb-0">
        {{ $t("Development Zones List") }}
      </h5>
      <v-spacer></v-spacer>
      <!-- add a button at right of v-card-title -->
      <v-btn v-if="hasPackageUpdateForAll" :ripple="false" elevation="0" color="#fff" class="
          font-weight-bolder
          btn-default
          bg-gradient-secondary
          py-5
          px-4
          mx-2
        " small @click="confirmImportAllTasks()">
        <v-icon class="me-2" size="12">fa fa-power-off</v-icon>
        匯入全部子檔
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table :headers="headers" :options.sync="options" :items="zones" :server-items-length="totalZones"
        :loading="data_loading" hide-default-footer disable-pagination show-expand :expanded.sync="expanded"
        single-expand class="row-height-50">
        <!--  action  -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="viewTasks(item)">mdi-home-search-outline</v-icon>
            </template>
            <span>檢視</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="exportTasks(item)" color="red">mdi-file-export-outline</v-icon>
            </template>
            <span>匯出</span>
          </v-tooltip>
          <v-tooltip bottom v-if="last_name == 'Kent'">
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="triggerFileInput(item)">mdi-upload</v-icon>
            </template>
            <span :style="{ color: item.location ? '' : '#9999ff' }">{{
              getLabel(item)
              }}</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon large class="mr-2" v-on="on" @click.stop="importTasks(item)">mdi-file-import-outline</v-icon>
            </template>
            <span>匯入子檔</span>
          </v-tooltip> -->
        </template>
        <!-- status -->
        <template v-slot:[`item.status`]="{ item }">
          <span :style="{ color: hasPackage(item.packages) ? 'red' : '' }">
            {{ hasPackage(item.packages) == true ? "有新子檔" : "已完成" }}
          </span>
        </template>
        <!-- expanded row content -->
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-4">
            <v-card flat>
              <v-card-title class="text-subtitle-1 font-weight-medium">
                子檔資訊
              </v-card-title>
              <v-card-text>
                <div>
                  <v-tabs v-model="activeTab" @change="onTabChange(item)">
                    <v-tab>子檔列表</v-tab>
                    <v-tab>子檔歷史</v-tab>

                    <v-tab-item>
                      <v-data-table :headers="packageHeaders" :items="item.packages" hide-default-footer
                        class="elevation-0" dense>
                        <template v-slot:[`item.status`]="{ item: pkg }">
                          <v-chip :color="pkg.status === 2 ? 'success' : 'warning'" small outlined>
                            {{ pkg.status === 2 ? "已完成" : "待處理" }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item: pkg }">
                          <v-tooltip v-if="pkg.status === 1" bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon large class="mr-2" v-on="on"
                                @click.stop="importTasks(pkg)">mdi-file-import-outline</v-icon>
                            </template>
                            <span>匯入子檔</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-tab-item>

                    <v-tab-item>
                      <v-data-table :headers="packageHistoryHeaders" :items="packages_history" hide-default-footer
                        class="elevation-0" dense>
                        <template v-slot:[`item.status`]="{ item: pkg }">
                          <v-chip :color="pkg.status === 2 ? 'success' : 'warning'" small outlined>
                            {{ pkg.status === 2 ? "已完成" : "待處理" }}
                          </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item: pkg }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon large class="mr-2" v-on="on"
                                @click.stop="downloadPackage(pkg)">mdi-file-import-outline</v-icon>
                            </template>
                            <span>下載(含zip)</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs>
                </div>
                <!-- <div v-else class="text-subtitle-2 grey--text">
                  無套件資訊
                </div> -->
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <div class="d-flex justify-end pt-4">
        <v-pagination v-model="options.page" :length="Math.ceil(totalZones / options.itemsPerPage)"
          :total-visible="7"></v-pagination>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
export default {
  name: "DevelopmentZone",

  data() {
    return {
      headers: [
        { text: "名稱", value: "name", sortable: false },
        { text: "描述", value: "description", sortable: false },
        { text: "狀態", value: "status", sortable: true },
        { text: "最後更新", value: "update_time", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      packageHeaders: [
        { text: "名稱", value: "name", sortable: false },
        // { text: "來源位置", value: "location", sortable: false },
        // { text: "合併檔案", value: "merged_file", sortable: false },
        { text: "狀態", value: "status", sortable: true },
        { text: "操作", value: "actions", sortable: false },
      ],
      packageHistoryHeaders: [
        { text: "名稱", value: "name", sortable: true },
        // { text: "來源位置", value: "location", sortable: false },
        { text: "上傳時間", value: "status", sortable: false },
        { text: "操作", value: "actions", sortable: false },
      ],
      zones: [],
      totalZones: 0,
      options: {
        page: 1,
        itemsPerPage: 30,
        sortBy: ["name"],
        sortDesc: [false],
      },
      data_loading: false,
      expanded: [],
      loading: {
        dialog: false,
        title: null,
        message: null,
        show_loading: true,
        show_confirm: false,
      },
      activeTab: 0,
      confirm: {
        dialog: false,
        title: null,
        message: null,
        function: null,
      },
      selectedItem: null,
      file: null,
      import_dialog: false,
      selected: [],
      packages_history: [],
    };
  },
  components: {
    CommonLoading,
    CommonConfirm,
  },
  computed: {
    ...mapState(["last_name"]),
    hasPackageUpdateForAll(){
      return this.zones.some(zone => this.hasPackage(zone.packages));
    }
  },

  watch: {
    options: {
      handler() {
        this.fetchDevelopmentZones();
      },
      deep: true,
    },
    expanded: {
      handler() {
        this.resetTab();
      },
    },
  },
  methods: {
    ...mapGetters(["getToken"]),
    viewTasks(item) {
      this.$router.push({
        name: "DevelopmentTask",
        params: { zone_id: item.id, lbtype: item.lbtype, zone_name: item.name },
      });
    },
    fetchDevelopmentZones() {
      console.log("fetchDevelopmentZones");
      const currentObj = this;
      this.data_loading = true;
      this.zones = [];
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
        params: {
          page: this.options.page,
          size: this.options.itemsPerPage,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.zones = (response.data.items || []).map((zone) => ({
            ...zone,
            packages: zone.packages,
          }));
          currentObj.totalZones = response.data.total || 0;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          this.data_loading = false;
        });
    },
    getLabel(item) {
      return item.location ? "上傳子檔" : "上傳母檔";
    },
    getDialogSuffix() {
      return ".xlsx,.zip";
      if (!this.selectedItem) {
        return ".xlsx,.zip";
      }
      if (this.selectedItem.location) {
        return ".zip";
      }
      return ".xlsx";
    },
    getDialogLabel() {
      if (!this.selectedItem) {
        return "上傳母檔";
      }
      return this.getLabel(this.selectedItem);
    },
    triggerFileInput(item) {
      this.selectedItem = item;
      // this.$refs.fileInput.click();
      this.import_dialog = true;
    },
    closeImportDialog() {
      this.import_dialog = false;
      this.file = null;
      this.data_loading = false;
    },
    handleFileSelect() {
      // const file = event.target.files[0];
      if (this.file) {
        this.uploadFile(this.selectedItem, this.file);
      }
    },
    hasPackage(packages) {
      if (!packages || packages.length == 0) return false;
      return packages.filter((pkg) => pkg.status === 1).length > 0;
    },
    async uploadFile(item, file) {
      const currentObj = this;
      this.data_loading = true;

      const url = item.location
        ? `${process.env.VUE_APP_SERVER_URL}/api/v1/packages/${item.id}`
        : `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/upload-main-file/${item.id}`;
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: this.getToken(),
        },
      };
      this.import_dialog = false;
      this.loading = {
        dialog: true,
        title: "上傳中",
        message: "上傳中",
        show_loading: true,
        show_confirm: false,
      };
      const formData = new FormData();
      formData.append("file", file);
      this.axios
        .post(url, formData, config)
        .then(function (response) {
          console.log("uploadFile", response);
          currentObj.closeImportDialog();
          currentObj.showInfoDialog("上傳成功", "上傳成功");
          // currentObj.fetchDevelopmentZones();
        })
        .catch((error) => {
          if (error.response != null && error.response.data != null) {
            console.error("Error downloading file:", error.response.data);
            const errorMessage =
              error.response.data.detail ||
              error.response.data.message ||
              "上傳失敗";
            currentObj.showInfoDialog("上傳失敗", errorMessage);
          }
        })
        .finally(() => {
          this.data_loading = false;
        });
    },
    showInfoDialog(title, message) {
      console.log("show dialog", title);
      this.loading = {
        dialog: true,
        title: title,
        message: message,
        show_loading: false,
        show_confirm: true,
      };
    },
    exportTasks(item) {
      // call /api/v1/development-zones/export-excel/
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/export-excel/${item.id}`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
        responseType: "blob", // important
      };
      this.loading = {
        dialog: true,
        title: "匯出中",
        message: "準備匯出母檔...",
      };

      this.axios
        .post(url, {}, config)
        .then((response) => {
          const filename = `${item.name}.xlsx`;
          this.forceFileDownload(response, filename);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          // this.$toast.error("Failed to download file");
        })
        .finally(() => {
          this.loading.dialog = false;
        });
    },
    forceFileDownload(response, filename) {
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", filename);

      document.body.appendChild(link);
      link.click();
    },
    importTasks(item) {
      console.log("importTasks", item);
      this.selectedItem = item;
      this.confirm = {
        dialog: true,
        title: "匯入子檔",
        message: "要匯入: " + item.name + " 子檔嗎?",
        function: this.doImportTasks,
      };
    },
    doImportTasks() {
      const item = this.selectedItem;
      const currentObj = this;
      console.log("doImportTasks", item);
      this.confirm.dialog = false;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/import`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      const data = {
        zone_id: item.development_zone_id,
        package_id: item.id,
      };
      this.axios
        .post(url, data, config)
        .then((response) => {
          currentObj.onImportTaskDone(response);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading.dialog = false;
        });
    },
    onImportTaskDone(response) {
      console.log("onImportTasks", response.data);
      this.showInfoDialog("匯入成功", "匯入子檔成功");
      this.fetchDevelopmentZones();
    },
    confirmImportAllTasks(){
      this.confirm = {
        dialog: true,
        title: "匯入全部子檔",
        message: "匯入全部子檔?",
        function: this.importAllTasks,
      };
    },
    importAllTasks() {
      // alert("尚未開放 匯入所有子檔");
      const item = this.selectedItem;
      const currentObj = this;
      console.log("doImportTasks", item);
      this.confirm.dialog = false;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/import_all_packages`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      const data = {};
      this.axios
        .post(url, data, config)
        .then((response) => {
          currentObj.onImportTaskDone(response);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading.dialog = false;
        });
    },
    getPackageHistory(item) {
      const zone_id = item.id;
      console.log("getPackageHistory", zone_id);
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/development-zones/${zone_id}/packages`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      this.axios
        .get(url, config)
        .then((response) => {
          console.log("getPackageHistory", response.data);
          this.packages_history = response.data;
          console.log("this.packages_history", this.packages_history);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          this.loading.dialog = false;
        });
    },
    onTabChange(item) {
      if (this.activeTab === 1) {
        // 子檔歷史 tab
        this.getPackageHistory(item);
      } else {
        this.packages_history = [];
      }
    },
    resetTab() {
      this.activeTab = 0;
      this.packages_history = [];
    },
    downloadPackage(pkg) {
      this.loading.dialog = true;
      this.loading.title = "下載中";
      this.loading.message = "正在下載子檔...";
      this.loading.show_loading = true;
      this.loading.show_confirm = false;
      
      const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/packages/${pkg.id}/zip`;
      
      this.axios
        .get(url, {
          responseType: "blob",
          headers: {
            Authorization: this.getToken(),
          },
        })
        .then((response) => {
          const filename = `${pkg.name}.zip`;
          this.forceFileDownload(response, filename);
        })
        .catch((error) => {
          console.error("Download error:", error);
          this.showInfoDialog("下載失敗", "無法下載套件，請稍後再試。");
        })
        .finally(() => {
          this.loading.dialog = false;
          this.loading.show_loading = false;
        });
    },
  },
};
</script>

<style scoped>
.row-height-50 ::v-deep tbody tr {
  height: 50px !important;
}
</style>
